(function() {
    if(window.oneX === undefined) {
       window.oneX = {};
    }

    oneX.Loader = (function() {
        function Loader(type, locale, limited, contentArea, languageLink) {
            let _self = this;
            this.default = 'unauth';
            this.type = type;
            this.locale = locale;
            this.isLimited = limited;
            this.contentArea = contentArea;
            this.languageLink = languageLink;
            this.isWASApp = function(){
              let _hostname =oneX.Util.location.hostname();
              return /sfstandard-\w*-(app|svc)-(isbx|dev|inqa|qc|preprod)-b2\w-\s*.*\.opr\.test/.test(_hostname) ||
                     /tc(dev1|i(dv|sp|pf))/.test(_hostname);
            };
            this.getSFXEnvironment = function(){
              let _hostname = oneX.Util.location.hostname();
              let _env;
                  if (/isbx|dev|inqa/.test(_hostname) || /tc(dev1|idv)/.test(_hostname) ){
                    _env = 'test1';
                  }
                  if ( /qc|tci(pf)/.test(_hostname) ) {
                    _env = 'test3';
                  }
                  if (/preprod/.test(_hostname) || /tci(sp)/.test(_hostname) ) {
                    _env = 'prep';
                }
                return _env;
            };
            this.host =  function(){
                let _env;
                if (this.isWASApp) {
                  _env = this.getSFXEnvironment();
                }
                return oneX.Util.environment.getStaticHostName(_env);
            }
            this.language =  '/'+this.locale+'_US';
            this.folder =  '/dxl-1x/prod';
            this.href = this.host()+this.language+this.folder;
            this.JSON =  {
                unauth : this.href+'/data/header/navigation-header-unauth.json',
                auth : this.href+'/data/header/navigation-header-auth.json',
                footer : this.href+'/data/footer/navigation-footer.json'
            };
            this.Template = {
                    header : this.href+'/template/header/1x-header.hbs',
                    header_mainMenu : this.href+'/template/header/_mainMenu.hbs',
                    header_loginMenu : this.href+'/template/header/_loginMenu.hbs',
                    header_accountsMenu : this.href+'/template/header/_accountsMenu.hbs',
                    header_languageModal : this.href+'/template/header/_languageModal.hbs',
                    header_helpMenu : this.href+'/template/header/_helpMenu.hbs',
                    footer : this.href+'/template/footer/1x-footer.hbs'
            };
            this._json = [];
            this._template = [];
            this.get = function( type ) {
                let template = Handlebars.compile(_self._template[type]);
                oneX.$('.-oneX-'+type).replaceWith(template(_self._json[type]));
                if (type === 'header'){
                   this.headerResources((this.type!==this.default), this.isLimited);
                }
            };
            this.headerResources = function(isAuth, isLimited) {
              oneX.Header.init();
              oneX.Icons.init(); 
              oneX.FloatingLabel.init();
              oneX.AutoFormat.init();
              oneX.Modal.init();    
              oneX.Help.init();             
      

              if (!isLimited) {
                oneX.Search.init();
              }
              else {
                oneX.HelpMenu.init();
              }

              if (!isAuth) {
                oneX.ButtonSelectors.init(); 
              }
            };
            this.bindHeader = function() {
                let _data = this.type===this.default ? _self.JSON.unauth : _self.JSON.auth;
                let jsonPromise = oneX.$.getJSON( _data );
                let hbsPromise = oneX.$.get(_self.Template.header);
                let hbs_area1_Promise = oneX.$.get(_self.Template.header_mainMenu);
                let _menu = this.type===this.default ?  _self.Template.header_loginMenu : _self.Template.header_accountsMenu;
                let hbs_area2_Promise = oneX.$.get(_menu);

                let hbs_modal_Promise = oneX.$.get(_self.Template.header_languageModal);
                let hbs_help_Promise = oneX.$.get(_self.Template.header_helpMenu);


                oneX.$.when(jsonPromise, hbsPromise, hbs_area1_Promise, hbs_area2_Promise, hbs_modal_Promise, hbs_help_Promise).done(function(json, hbs, hbs_area1, hbs_area2, hbs_modal, hbs_help) {
                    json = json[0];
                    if (_self.isLimited) {
                      json.structure.navbar.limited = true;
                    }
                    if (_self.contentArea !== null) {
                      json.structure.navbar.contentArea = _self.contentArea;
                    }
                    let _menu = _self.type===_self.default ?  "_loginMenu" : "_accountsMenu";
                    _self._json.header = json;
                    _self._template.header = hbs[0] ;
                    Handlebars.registerPartial("_mainMenu", hbs_area1[0]);
                    Handlebars.registerPartial(_menu, hbs_area2[0]);
                    Handlebars.registerPartial("_languageModal", hbs_modal[0]);
                    Handlebars.registerPartial("_helpMenu", hbs_help[0]);
                    _self.get("header");
                });
            };
            this.bindFooter = function() {
                let jsonPromise = oneX.$.getJSON(_self.JSON.footer);
                let hbsPromise = oneX.$.get(_self.Template.footer);

                oneX.$.when(jsonPromise, hbsPromise).done(function(json, hbs) {
                   json = json[0];
                   if (_self.isLimited) {
                     json.structure.footerText.limited = true;
                   }
                   if (_self.languageLink!==null) {
                     json.structure.languageLink.url  = _self.languageLink;
                   }
                  _self._json.footer = json;
                  _self._template.footer = hbs[0] ;
                  _self.get("footer");
                });
            };
            _self.bindHeader();
            _self.bindFooter();
        }
        return {
            callback: null,
            isLimited: false,
            isAuth: false,
            isSpanish: false,
            contentArea: '#content',
            languageLink: null,
            init: function() {
              let _type = this.isAuth ?  "auth" : "unauth";
              let _locale = this.isSpanish ?  "es" : "en";
              new Loader(_type, _locale, this.isLimited, this.contentArea, this.languageLink);
              if (typeof this.callback === 'function' ) {
                setTimeout(function(){  this.oneX.Loader.callback.call(); }, 750);
              }
            }
        };
    }).call(oneX);

})();
